import { UserWithStaffPositions } from '@ivaoaero/atc';

import { useQuery } from 'react-query';

import Http from '../../../utils/Http';
import { useAuth } from './useAuth';

const fetchUser = () =>
  Http<UserWithStaffPositions>({
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/users/me`,
  });

export const useUser = () => {
  const auth = useAuth();
  const { data, ...rest } = useQuery(['user'], fetchUser, {
    enabled: auth.isAuthenticated,
    staleTime: Infinity,
  });

  return {
    user:
      data ??
      ({
        id: 0,
        firstName: '',
        lastName: '',
        divisionId: '',
        userStaffPositions: [],
        rating: {
          isPilot: false,
          isAtc: false,
          atcRating: undefined,
          pilotRating: null,
        },
      } as UserWithStaffPositions),
    ...rest,
  };
};
