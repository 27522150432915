import { Booking, BookingRequestDto, BookingResponseDto } from '@ivaoaero/atc';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { ApiErrorResponse } from '../modules/core/types/api';
import { IDivision } from '../modules/core/types/division';
import { IFras } from '../modules/core/types/fras';
import Http from './Http';

export const getAllBookings = (date: Date): Promise<Booking[]> =>
  Http<BookingResponseDto[]>({
    url: `/v2/atc/bookings/daily?date=${dayjs(date).format('YYYY-MM-DD')}`,
  }).then((items: BookingResponseDto[]) => {
    const out: Booking[] = [];

    items.forEach((i) => out.push(new Booking().parse_from_dto(i)));

    return out;
  });

export const createBooking = async (
  data: BookingRequestDto,
): Promise<BookingResponseDto> => {
  try {
    return Http<BookingResponseDto>({
      method: 'POST',
      url: '/v2/atc/bookings',
      data,
    });
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (
        axiosError.response &&
        Array.isArray(axiosError.response?.data.message)
      ) {
        throw new BookingError(axiosError.response.data.message);
      } else if (axiosError.response?.data.message) {
        throw new BookingError([axiosError.response.data.message]);
      }
    }
    throw new BookingError(['Internal server error']);
  }
};

export const editBooking = async (
  id: number,
  data: BookingRequestDto,
): Promise<BookingResponseDto> => {
  try {
    return Http<BookingResponseDto>({
      method: 'PUT',
      url: `/v2/atc/bookings/${id}`,
      data,
    });
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (
        axiosError.response &&
        Array.isArray(axiosError.response?.data.message)
      ) {
        throw new BookingError(axiosError.response.data.message);
      } else if (axiosError.response?.data.message) {
        throw new BookingError([axiosError.response.data.message]);
      }
    }
    throw new BookingError(['Internal server error']);
  }
};

export const deleteBooking = async (id: number): Promise<boolean> => {
  try {
    const response: BookingResponseDto = await Http<BookingResponseDto>({
      method: 'DELETE',
      url: `/v2/atc/bookings/${id}`,
    });

    return response ? true : false;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (
        axiosError.response &&
        Array.isArray(axiosError.response?.data.message)
      ) {
        throw new BookingError(axiosError.response.data.message);
      } else if (axiosError.response?.data.message) {
        throw new BookingError([axiosError.response.data.message]);
      }
    }
    throw new BookingError(['Internal server error']);
  }
};

export const getAllFras = (
  divisionId: string | undefined,
  page: number,
  perPage: number,
  isActive: boolean,
  expand: boolean,
  startsWith?: string,
): Promise<IFras> =>
  Http<IFras>({
    url: `${import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''}/v2/fras`,
    params: {
      page,
      perPage,
      isActive,
      members: false, // Do not include member-fra,
      expand,
      ...(startsWith ? { startsWith } : {}),
      ...(divisionId ? { divisionId } : {}),
    },
  }).then((req) => {
    return req;
  });

export const getAllDivisions = (): Promise<IDivision[]> =>
  Http<IDivision[]>({
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/divisions/all`,
  });

export const fetchAutocompleteData = (startsWith: string): Promise<string[]> =>
  Http<string[]>({
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/positions/search?startsWith=${startsWith}&limit=10&composePositionList=true`,
  });

export const checkUserFras = async (
  userId: number,
  callsign: string,
  startDate: string,
): Promise<string> => {
  return Http<{ message: string; description: string }>({
    method: 'GET',
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/fras/check/${callsign}/${userId}?startDate=${startDate}`,
  }).then((res) => res.description);
};

export class BookingError extends Error {
  public constructor(public readonly messages: string[] = []) {
    super(messages.join('\n'));
  }
}
