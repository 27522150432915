import { BookingType } from '@ivaoaero/atc';

import { showNotification, updateNotification } from '@mantine/notifications';
import { IconAlertCircle, IconCheck, IconVocabulary } from '@tabler/icons';

export const showError = (title: string, message: string) => {
  showNotification({
    title: title,
    message: message,
    icon: <IconAlertCircle size={18} />,
    color: 'red',
    styles: { title: { fontWeight: 'bold' } },
  });
};

export const showSuccess = (title: string, message: string) => {
  showNotification({
    title: title,
    message: message,
    icon: <IconCheck size={18} />,
    color: 'green',
    styles: { title: { fontWeight: 'bold' } },
  });
};

export const showTraining = (type: BookingType) => {
  showNotification({
    title: type === BookingType.TRAINING ? 'Good Luck!' : "You've got this!",
    message:
      type === BookingType.TRAINING
        ? 'Your booking is for a training'
        : 'Your booking is for an exam',
    icon: <IconVocabulary size={18} />,
    color: 'ivao-blue',
    styles: { title: { fontWeight: 'bold' } },
  });
};

export const showLoading = () => {
  showNotification({
    id: 'load-data',
    loading: true,
    title: 'Sending...',
    message: 'Your booking is sent to our servers, standby',
    autoClose: false,
    disallowClose: true,
    styles: { title: { fontWeight: 'bold' } },
  });
};

enum States {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const showFinishedLoading = (state: 'SUCCESS' | 'ERROR') => {
  if (state === States.SUCCESS) {
    updateNotification({
      id: 'load-data',
      title: 'Your booking was registered',
      message: 'Have fun controlling!',
      icon: <IconCheck size={18} />,
      color: 'green',
      styles: { title: { fontWeight: 'bold' } },
    });
  } else {
    updateNotification({
      id: 'load-data',
      title: 'Your booking could not be registered',
      message: 'Please try again!',
      icon: <IconAlertCircle size={18} />,
      color: 'red',
      styles: { title: { fontWeight: 'bold' } },
    });
  }
};
