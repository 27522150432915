import { useMantineColorScheme } from '@mantine/core';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

const Header = () => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <header className={styles.header}>
      <Link
        to="/"
        replace
        onClick={() => {
          if (window.location.pathname === '/') {
            window.location.reload();
          }
        }}
      >
        <img
          className={styles.image}
          alt="IVAO Logo"
          src={
            colorScheme === 'dark'
              ? 'https://static.ivao.aero/img/logos/logo_white.svg'
              : 'https://static.ivao.aero/img/logos/logo.svg'
          }
        />
      </Link>
    </header>
  );
};

export default Header;
