import { Route, Routes } from 'react-router-dom';

import AuthPage from '../auth/components/AuthPage';
import Layout from './components/Layout';
import { FRAWapper } from './pages/Fras';
import { Home } from './pages/Home';
import LogoutPage from './pages/Logout';
import { NotFound } from './pages/NotFound';
import { Schedule } from './pages/Schedule';

export const CoreRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      ></Route>
      <Route path="/logout" element={<LogoutPage />}></Route>
      <Route
        path="/schedule"
        element={
          <Layout>
            <AuthPage>
              <Schedule />
            </AuthPage>
          </Layout>
        }
      ></Route>
      <Route
        path="/fras"
        element={
          <Layout>
            <AuthPage>
              <FRAWapper />
            </AuthPage>
          </Layout>
        }
      ></Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};
