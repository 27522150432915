import { Booking } from '@ivaoaero/atc';
import { Button } from '@ivaoaero/design-system';

import { Modal } from '@mantine/core';
import { useQueryClient } from 'react-query';

import { BookingError, deleteBooking } from '../../../../utils/ApiRequest';
import { useUser } from '../../../auth';
import { showError, showSuccess } from '../Notifications';

interface DeleteModalProps {
  opened: boolean;
  close(): void;
  booking?: Booking;
}

const DeleteModal = (props: DeleteModalProps) => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const handleDelete = () => {
    if (!props.booking) return;
    deleteBooking(props.booking.id!)
      .then((b) => {
        if (b)
          user.id === props.booking?.user.id
            ? showSuccess(
                'Deleted successfully',
                'Your booking has been deleted',
              )
            : showSuccess('Deleted successfully', 'This is real power');
        else
          user.id === props.booking?.user.id
            ? showError(
                'Error while deleting your booking',
                'Your booking could not be deleted',
              )
            : showError(
                'Error while deleting the booking',
                'The server was not able to handle that much of power',
              );

        void queryClient.invalidateQueries([
          'bookings',
          props.booking?.startDate,
        ]);
      })
      .catch((e) => {
        if (e instanceof BookingError)
          showError('Error while deleting the booking', e.message);
        else
          showError(
            'Error while deleting the booking',
            'The server was not able to handle that much of power',
          );
      })
      .finally(() => {
        props.close();
      });
  };
  return (
    <>
      <Modal
        opened={props.opened}
        onClose={() => props.close()}
        title="Confirm Deletion of Booking"
      >
        <Button variant="primary" color="danger" onClick={handleDelete}>
          Confirm
        </Button>
      </Modal>
    </>
  );
};

export default DeleteModal;
