import { useQuery } from 'react-query';

import Http from '../../../utils/Http';
import { useAuth } from './useAuth';

type UserPermissions = {
  [key: string]: {
    id: number;
    permissionId: string;
    allowed: boolean;
    permission: {
      id: string;
      description: string;
    };
  };
};

const fetchUserPermissions = () =>
  Http<UserPermissions>({
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/users/me/permissions/all`,
  });

export const usePermissions = () => {
  const auth = useAuth();
  const query = useQuery(['user', 'permissions'], fetchUserPermissions, {
    enabled: auth.isAuthenticated,
    staleTime: Infinity,
  });

  return query;
};
