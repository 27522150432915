import { useEffect } from 'react';

import { PulseLoader } from '@ivaoaero/design-system';

import { useAuth } from '../../auth';
import { useSettings } from '../../auth/hooks/useSettings';
import { Centered } from '../components/Centered';

const LogoutPage = () => {
  const auth = useAuth();
  const settings = useSettings();

  useEffect(() => {
    void auth.signOut(settings.data?.sso_logout);
  }, [auth, settings.data?.sso_logout]);

  return (
    <Centered>
      <PulseLoader />
    </Centered>
  );
};

export default LogoutPage;
