import { useEffect, useState } from 'react';

import { Booking } from '@ivaoaero/atc';
import { PulseLoader } from '@ivaoaero/design-system';

import { NotificationsProvider } from '@mantine/notifications';

import useGetAllBookings from '../../../utils/hooks/useGetAllBookings';
import { useQueryState } from '../../../utils/hooks/useQueryState';
import { useUser } from '../../auth';
import BookingDrawer from '../components/BookingDrawer';
import { Centered } from '../components/Centered';
import { Day } from '../components/Day';
import { Footer } from '../components/Footer';
import Menu from '../components/Menu';
import SchedulingHeader from '../components/SchedulingHeader';
import { ShownDate } from '../types/shownDate';

export const Schedule = () => {
  const userResult = useUser();

  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

  const [filter, setFilter] = useQueryState('filter');

  const filterBookings = (
    allBookings: Booking[],
    filter: string,
  ): Booking[] => {
    return allBookings.filter(
      isNaN(filter as unknown as number)
        ? (b) => b.atcPosition.toLowerCase().indexOf(filter.toLowerCase()) != -1
        : (b) => b.user.id.toString().indexOf(filter) != -1,
    );
  };

  const [booking, setBooking] = useState<Booking>(new Booking());
  const [editing, setEditing] = useState<boolean>(false);

  const edit = (booking: Booking) => {
    setBooking(booking);
    setEditing(true);
    setDrawerOpened(true);
  };

  const [shownDate, setShownDate] = useState<ShownDate>(new ShownDate());
  const allBookings = useGetAllBookings(shownDate.date);

  useEffect(() => {
    if (allBookings.isLoading) return;
    void allBookings.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownDate]);

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com/" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;800&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swapl"
        rel="stylesheet"
      />
      <NotificationsProvider>
        <SchedulingHeader setDrawerOpened={setDrawerOpened} />
        <BookingDrawer
          opened={drawerOpened}
          setOpened={setDrawerOpened}
          editing={editing}
          setEditing={setEditing}
          booking={booking}
          setBooking={setBooking}
          userId={userResult.user.id}
          setShownDate={setShownDate}
          shownDate={shownDate}
        />
        <div className="container mx-auto">
          <Menu
            date={shownDate}
            setDate={setShownDate}
            filter={filter}
            setFilter={setFilter}
          />
          {allBookings.data && !allBookings.isLoading ? (
            <Day
              bookings={
                filter
                  ? filterBookings(allBookings.data, filter)
                  : allBookings.data
              }
              date={shownDate}
              edit={edit}
              filter={filter}
            />
          ) : (
            <Centered>
              <PulseLoader />
            </Centered>
          )}
        </div>
        <Footer />
      </NotificationsProvider>
    </>
  );
};
