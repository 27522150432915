import { useMantineColorScheme } from '@mantine/core';
import { Theme } from 'planby';

export const useTimelineTheme = (): Theme => {
  const { colorScheme } = useMantineColorScheme();

  return colorScheme === 'dark' ? timelineThemeDark : timelineThemeWhite;
};

const timelineThemeDark: Theme = {
  primary: {
    600: '#2e3236',
    900: '#25262b',
  },
  grey: { 300: '#d1d1d1' },
  white: '#fff',
  green: {
    300: '#718096',
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  gradient: {
    blue: {
      300: '#3C55AC',
      600: '#3C55AC',
      900: '#0D2C99',
    },
  },
  text: {
    grey: {
      300: '#a0aec0',
      500: '#718096',
    },
  },
  timeline: {
    divider: {
      bg: '#718096',
    },
  },
};

const timelineThemeWhite = {
  primary: {
    600: '#F7FAFC',
    900: '#ffffff',
  },
  grey: { 300: '#2D3748' },
  white: '#1A202C',
  teal: {
    100: '#5DDADB',
  },
  green: {
    200: '#389493',
    300: '#2C7A7B',
  },
  scrollbar: {
    border: '#171923',
    thumb: {
      bg: '#718096',
    },
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  gradient: {
    blue: {
      300: '#A0AEC0',
      600: '#E2E8F0',
      900: '#A0AEC0',
    },
  },
  text: {
    grey: {
      300: '#2D3748',
      500: '#1A202C',
    },
  },
  timeline: {
    divider: {
      bg: '#1A202C',
    },
  },
};
