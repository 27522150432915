import { useCallback, useEffect } from 'react';

import { ColorScheme } from '@mantine/core';

import { useSettings } from './useSettings';

interface UseDarkModeProps {
  toggleColorScheme: (value?: ColorScheme) => void;
}

export const useDarkMode = ({ toggleColorScheme }: UseDarkModeProps) => {
  const darkTheme = useSettings();

  const isUsingDarkMode = useCallback(() => {
    if (darkTheme.status !== 'success')
      return (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      );

    return darkTheme.data?.dark_mode;
  }, [darkTheme]);

  useEffect(() => {
    if (isUsingDarkMode()) {
      document.body.setAttribute('data-theme', 'dark');
      document.documentElement.classList.add('dark');
      toggleColorScheme('dark');
    } else {
      document.body.setAttribute('data-theme', 'light');
      document.documentElement.classList.remove('dark');
      toggleColorScheme('light');
    }
  }, [isUsingDarkMode, toggleColorScheme]);
};
