import { useEffect, useState } from 'react';

import { UserWithStaffPositions } from '@ivaoaero/atc';
import { PulseLoader } from '@ivaoaero/design-system';

import { Skeleton } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

import useGetAllFras from '../../../utils/hooks/useGetAllFras';
import { useQueryState } from '../../../utils/hooks/useQueryState';
import { useUser } from '../../auth';
import { Centered } from '../components/Centered';
import { Footer } from '../components/Footer';
import { FraChecker } from '../components/FrasChecker';
import FrasHeader from '../components/FrasHeader';
import FrasMenu from '../components/FrasMenu';
import FrasPagination from '../components/FrasPagination';
import { FrasTimeline } from '../components/FrasTimeline';
import { ShownDate } from '../types/shownDate';

interface FRAPageProps {
  user: UserWithStaffPositions;
}

const FRAPage = ({ user }: FRAPageProps) => {
  const [shownDate, setShownDate] = useState<ShownDate>(new ShownDate());
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useQueryState<number>('page', {
    defaultValue: 1,
  });
  const [perPage, setPerPage] = useQueryState<number>('perPage', {
    defaultValue: 30,
  });
  const [filter, setFilter] = useQueryState('filter', { debounced: true });
  const [divisionFilter, setDivisionFilter] = useQueryState('division', {
    defaultValue: user.divisionId,
  });
  const allFras = useGetAllFras(
    divisionFilter,
    activePage && activePage >= 1 ? activePage : 1,
    perPage ?? 30,
    true,
    true,
    filter,
  );

  useEffect(() => {
    if (activePage && activePage > Number(totalPages))
      setActivePage(Number(totalPages));
  }, [totalPages, activePage, setActivePage]);

  useEffect(() => {
    if (allFras.data?.pages) setTotalPages(allFras.data.pages);
  }, [allFras.data?.pages]);

  return (
    <>
      <NotificationsProvider>
        <FrasHeader />
        <FrasMenu
          date={shownDate}
          filter={filter}
          perPage={perPage ?? 30}
          setDate={setShownDate}
          setFilter={setFilter}
          setPerPage={setPerPage}
          divisionFilter={divisionFilter}
          setDivisionFilter={setDivisionFilter}
        />
        <Skeleton
          visible={
            !allFras.data?.items || allFras.isLoading || allFras.isFetching
          }
        >
          <FrasTimeline
            date={shownDate}
            userId={user.id}
            fras={allFras?.data?.items}
          />
        </Skeleton>
        <FrasPagination
          activePage={activePage ?? 1}
          totalPages={totalPages}
          setActivePage={setActivePage}
        />
        <FraChecker />
        <Footer />
      </NotificationsProvider>
    </>
  );
};

export const FRAWapper = () => {
  const { user, isLoading } = useUser();

  if (!user || isLoading) {
    return (
      <Centered>
        <PulseLoader />
      </Centered>
    );
  }

  return <FRAPage user={user} />;
};
