import { showNotification } from '@mantine/notifications';
import { useQuery } from 'react-query';

import { IDivision } from '../../modules/core/types/division';
import { getAllDivisions } from '../ApiRequest';

const useGetAllDivisions = () => {
  return useQuery<IDivision[]>(['divisions'], () => getAllDivisions(), {
    staleTime: 5 * 3600 * 1000,
    retry: (failureCount) => {
      return failureCount <= 5;
    },
    onError: () => {
      showNotification({
        title: 'Error!',
        message: 'I could not load divisions data.',
        color: 'red',
        styles: { title: { fontWeight: 'bold' } },
      });
    },
  });
};

export default useGetAllDivisions;
