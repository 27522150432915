import { useCallback, useEffect, useState } from 'react';

import { useDebouncedValue } from '@mantine/hooks';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

type UseQueryState<T> = [T | undefined, (value: T) => void];

interface UseQueryStateOptions<T> {
  debounced?: boolean;
  defaultValue?: T;
}

export const useQueryState = <T = string>(
  query: string,
  options?: UseQueryStateOptions<T>,
): UseQueryState<T> => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryValue, setQueryValue] = useState<T | undefined>(
    (qs.parse(location.search, { ignoreQueryPrefix: true })[query] as T) ??
      options?.defaultValue,
  );
  const [debouncedQueryValue] = useDebouncedValue(queryValue, 200);

  const setQuery = useCallback<(value: T) => void>(
    (value) => {
      setQueryValue(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, query],
  );

  useEffect(() => {
    const existingQueries = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const queryString = qs.stringify(
      { ...existingQueries, [query]: queryValue || null },
      { skipNulls: true },
    );

    navigate(`${location.pathname}?${queryString}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQueryValue]);

  return [options?.debounced ? debouncedQueryValue : queryValue, setQuery];
};
