import { PropsWithChildren } from 'react';

import { PulseLoader } from '@ivaoaero/design-system';

import { Centered } from '../../core/components/Centered';
import { useAuth } from '../hooks/useAuth';

const AuthPage = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  if (!auth.isAuthenticated) {
    auth.signIn();
    return (
      <Centered>
        <PulseLoader />
      </Centered>
    );
  }
  return <>{children}</>;
};

export default AuthPage;
