import { useEffect, useState } from 'react';

import {
  Booking,
  BookingPermission,
  BookingType,
  canManageBooking,
  UserWithStaffPositions,
} from '@ivaoaero/atc';

import {
  ActionIcon,
  Badge,
  Card,
  Flex,
  Group,
  HoverCard,
  List,
  Stack,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import {
  IconBook,
  IconMicrophone,
  IconMicrophoneOff,
  IconPencil,
  IconStar,
  IconTrashX,
} from '@tabler/icons';
import dayjs from 'dayjs';
import {
  Channel,
  ChannelBox,
  Epg,
  Layout,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramItem,
  ProgramStack,
  ProgramTitle,
  useEpg,
  useProgram,
} from 'planby';

import { RatingColorCode, RatingName } from '../../../../utils/Ratings';
import { userNickname } from '../../../../utils/UserNickname';
import { useUser } from '../../../auth';
import { usePermissions } from '../../../auth/hooks/usePermissions';
import { useTimelineTheme } from '../../types/colorScheme';
import { ShownDate } from '../../types/shownDate';
import customStyles from './index.module.scss';

interface CustomProgramItemProps {
  user: UserWithStaffPositions;
  programItem: ProgramItem;
  editBooking(b: Booking): void;
  booking: Booking;
  handleDelete(b: Booking): void;
  readOnly?: boolean;
}

const CustomProgramItem = (props: CustomProgramItemProps) => {
  const { isRTL, isLive, styles } = useProgram(props.programItem);

  const { data: userPermissions } = usePermissions();
  const { user } = useUser();
  const [canManage, setCanManage] = useState<BookingPermission[]>([]);

  useEffect(() => {
    if (user && userPermissions)
      setCanManage(
        canManageBooking(Object.keys(userPermissions), user.id, props.booking),
      );
  }, [user, userPermissions, props.booking]);

  return (
    <HoverCard
      width={400}
      position="bottom"
      withArrow
      shadow="md"
      openDelay={100}
      closeDelay={100}
    >
      <HoverCard.Target>
        <ProgramBox width={styles.width} style={styles.position}>
          <ProgramContent
            width={styles.width}
            isLive={isLive}
            style={{
              padding: '0px',
              height: '100%',
            }}
          >
            <ProgramFlex>
              <ProgramStack isRTL={isRTL} style={{ width: '100%' }}>
                <ProgramTitle
                  style={{
                    backgroundColor:
                      RatingColorCode[
                        props.booking.user.rating.atcRatingId ?? 0
                      ],
                    paddingTop: '5px',
                    height: '100%',
                  }}
                >
                  <span className={customStyles.programText}>
                    {!props.booking.voice ? <IconMicrophoneOff /> : <></>}
                    {props.booking.training === BookingType.TRAINING ? (
                      <IconBook />
                    ) : props.booking.training === BookingType.EXAM ? (
                      <IconStar />
                    ) : (
                      <></>
                    )}
                  </span>
                </ProgramTitle>
              </ProgramStack>
            </ProgramFlex>
          </ProgramContent>
        </ProgramBox>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Card>
          <Group position="apart" align="center">
            <Text weight={600} style={{ display: 'flex' }}>
              {props.booking.atcPosition} (
              {(props.booking.atcPositionRef?.frequency
                .toFixed(3)
                .toString() as string) ??
                props.booking.subcenterRef?.frequency.toFixed(3).toString()}
              ){!props.booking.voice ? <IconMicrophoneOff size={20} /> : ''}
            </Text>
            {props.booking.training ? (
              <Badge
                variant="light"
                styles={{
                  inner: {
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: 'normal',
                  },
                  root: { backgroundColor: '#0D2C99', marginBottom: '10px' },
                }}
              >
                {props.booking.training}
              </Badge>
            ) : (
              ''
            )}
          </Group>
          <hr />
          <Group position="apart" align="center" style={{ marginTop: '5px' }}>
            <p style={{ margin: 0, fontWeight: 400 }}>
              {props.booking.user.firstName
                ? `${userNickname(props.booking.user)}`
                : props.booking.user.id}
            </p>
            {props.booking.startDate.getHours().toString().padStart(2, '0') +
              ':' +
              props.booking.startDate.getMinutes().toString().padStart(2, '0') +
              'z - ' +
              props.booking.endDate.getHours().toString().padStart(2, '0') +
              ':' +
              props.booking.endDate.getMinutes().toString().padStart(2, '0') +
              'z'}
          </Group>
          {!props.readOnly ? (
            <div style={{ display: 'flex' }}>
              {canManage.includes(BookingPermission.EDIT) ? (
                <ActionIcon
                  variant="subtle"
                  size={20}
                  style={{
                    padding: '1px',
                    backgroundColor: '#0D2C99',
                    margin: '5px',
                  }}
                  onClick={() => props.editBooking(props.booking)}
                >
                  <IconPencil color="white"></IconPencil>
                </ActionIcon>
              ) : (
                <></>
              )}
              {canManage.includes(BookingPermission.DELETE) ? (
                <ActionIcon
                  variant="subtle"
                  size={20}
                  style={{
                    padding: '1px',
                    backgroundColor: 'red',
                    margin: '5px',
                  }}
                  onClick={() => props.handleDelete(props.booking)}
                >
                  <IconTrashX color="white"></IconTrashX>
                </ActionIcon>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </Card>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

interface ChannelProps {
  channel: Channel;
}

const ChannelItem = ({ channel }: ChannelProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <ChannelBox {...channel.position}>
      <p
        style={{
          color: colorScheme === 'dark' ? '#fff' : '#000',
          textAlign: 'center',
          fontFamily: 'Nunito Sans',
          fontWeight: '600',
          fontSize: '14px',
        }}
      >
        {channel.logo}
      </p>
    </ChannelBox>
  );
};

interface TimelineProps {
  bookings: Booking[];
  date: ShownDate;
  editBooking(b: Booking): void;
  handleDelete: (b: Booking) => void;
  readOnly?: boolean;
  user: UserWithStaffPositions;
}

interface ChannelItemProps {
  logo: string;
  uuid: string;
}

interface EPGProps {
  id: string;
  channelUuid: string;
  description: string;
  title: string;
  since: Date;
  till: Date;
  image: string;
}

export const Timeline = (props: TimelineProps) => {
  const { colorScheme } = useMantineColorScheme();
  const timelineTheme = useTimelineTheme();

  const channels: ChannelItemProps[] = [];
  const epg: EPGProps[] = [];

  props.bookings.forEach((b) => {
    if (
      !channels.some((temp: ChannelItemProps) => {
        return temp.uuid === b.atcPosition;
      })
    ) {
      channels.push({
        logo: b.atcPosition,
        uuid: b.atcPosition,
      });
    }

    epg.push({
      channelUuid: b.atcPosition,
      description: b as unknown as string,
      id: b.id?.toString() ?? '',
      since: b.startDate,
      till: b.endDate,
      title: b.user.id.toString(),
      image: '',
    });
  });

  // Sort ATC positions alphabetically
  channels.sort((a: ChannelItemProps, b: ChannelItemProps) => {
    if (a.uuid > b.uuid) {
      return 1;
    } else if (a.uuid < b.uuid) {
      return -1;
    } else {
      return 0;
    }
  });

  const { getEpgProps, getLayoutProps } = useEpg({
    epg,
    channels,
    startDate: props.date.date.toISOString(),
    endDate: dayjs(props.date.date).add(24, 'hours').toISOString(),
    dayWidth: 2000,
    isLine: false, // Currently it is displayed in local time, so disabling it until a fix is found https://github.com/karolkozer/planby/issues/19
    isTimeline: true,
    itemHeight: 40,
    sidebarWidth: 120,
    theme: timelineTheme,
  });

  return (
    <Stack className="max-w-[95vw]" spacing="md">
      <Epg {...getEpgProps()}>
        <Layout
          {...getLayoutProps()}
          renderChannel={({ channel }) => (
            <ChannelItem key={channel.uuid} channel={channel} />
          )}
          renderProgram={(programItem) => {
            return (
              <CustomProgramItem
                key={programItem.program.data.id}
                programItem={programItem}
                editBooking={(b) => props.editBooking(b)}
                handleDelete={props.handleDelete}
                user={props.user}
                // Passing booking entity as `description`
                booking={
                  programItem.program.data.description as unknown as Booking
                }
              />
            );
          }}
        />
      </Epg>
      <Flex
        gap="lg"
        justify="space-evenly"
        direction={{ md: 'row', base: 'column' }}
        className="text-white"
      >
        <Stack spacing="xs" maw="100px" className="mx-auto">
          <Text
            className={`${
              colorScheme === 'dark' ? `text-white` : 'text-black'
            } text-center font-bold`}
          >
            Color codes:
          </Text>
          {RatingName.map((name, i) =>
            name != 'Invalid' ? (
              <p
                className={customStyles.legend}
                style={{ backgroundColor: RatingColorCode[i] }}
                key={i}
              >
                {name}
              </p>
            ) : (
              <></>
            ),
          )}
        </Stack>
        <Stack
          className={`${
            colorScheme === 'dark' ? `text-white` : 'text-black'
          } mx-auto`}
        >
          <Text className={`text-center font-bold`}>Information:</Text>
          <List>
            <List.Item>
              Click on a booking to display additional information.
            </List.Item>
            <List.Item>All times on this page are in UTC.</List.Item>
          </List>
        </Stack>
        <Stack
          spacing="xs"
          className={`${
            colorScheme === 'dark' ? `text-white` : 'text-black'
          } mx-auto`}
        >
          <Text className="text-center font-bold">Icons:</Text>
          <p className={`${customStyles.legend} flex`}>
            <IconMicrophone />: Voice
          </p>
          <p className={`${customStyles.legend} flex`}>
            <IconMicrophoneOff />: No Voice
          </p>
          <p className={`${customStyles.legend} flex`}>
            <IconBook />: Training
          </p>
          <p className={`${customStyles.legend} flex`}>
            <IconStar />: Exam
          </p>
        </Stack>
      </Flex>
    </Stack>
  );
};
