import { useCallback, useEffect, useState } from 'react';

import { Booking, BookingType, checkBookingTime } from '@ivaoaero/atc';
import { Button } from '@ivaoaero/design-system';

import {
  Autocomplete,
  Checkbox,
  Drawer,
  NumberInput,
  Stack,
} from '@mantine/core';
import { DatePicker, TimeRangeInput } from '@mantine/dates';
import {
  IconCalendar,
  IconClock,
  IconIdBadge2,
  IconMapPin,
} from '@tabler/icons';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';

import {
  checkUserFras,
  createBooking,
  editBooking,
  fetchAutocompleteData,
} from '../../../../utils/ApiRequest';
import { usePermissions } from '../../../auth/hooks/usePermissions';
import { ApiErrorResponse } from '../../types/api';
import { ShownDate } from '../../types/shownDate';
import {
  showError,
  showFinishedLoading,
  showLoading,
  showTraining,
} from '../Notifications';
import styles from './index.module.scss';

const transformDateToUTC = (date: Date) =>
  new Date(dayjs(date).add(date.getTimezoneOffset(), 'minute').toDate());

interface BookingDrawerProps {
  opened: boolean;
  setOpened(opened: boolean): void;
  editing: boolean;
  booking: Booking;
  setBooking(booking: Booking): void;
  userId: number;
  setEditing(editing: boolean): void;
  shownDate: ShownDate;
  setShownDate(date: ShownDate): void;
}

const BookingDrawer = (props: BookingDrawerProps) => {
  const [position, setPosition] = useState<string>(props.booking.atcPosition);
  const [startDate, setStartDate] = useState<Date>(
    props.booking.id ? props.booking.startDate : props.shownDate.date,
  );
  const [endDate, setEndDate] = useState<Date>(props.booking.endDate);
  const [startTime, setStartTime] = useState<Date>(
    transformDateToUTC(props.booking.startDate),
  );
  const [endTime, setEndTime] = useState<Date>(
    transformDateToUTC(props.booking.endDate),
  );
  const [usesVoice, setUsesVoice] = useState<boolean>(props.booking.voice);
  const [training, setTraining] = useState<BookingType | null>(
    props.booking.training ?? null,
  );

  const [autoCompleteData, setAutoCompleteData] = useState<string[]>([]);
  const [lastChecked, setLastChecked] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const queryClient = useQueryClient();

  const { data: userPermissions } = usePermissions();

  const getAutocompleteData = useCallback(
    (position: string) => {
      if (
        position.length % 2 === 0 &&
        position.length !== 0 &&
        position.length !== lastChecked
      ) {
        setLastChecked(position.length);

        void fetchAutocompleteData(position).then((i) => {
          i.forEach((val, ind) => {
            val.includes('ATIS') ? i.splice(ind, 1) : null; // remove ATIS from autocomplete data
          });

          setAutoCompleteData(i);
        });
      }
    },
    [lastChecked],
  );

  function concatTime(date: Date, time: Date) {
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(0);

    // Set displayed time to UTC
    return new Date(
      dayjs(date).subtract(date.getTimezoneOffset(), 'minute').toDate(),
    );
  }

  useEffect(() => {
    getAutocompleteData(position);
  }, [getAutocompleteData, position]);

  function resetData() {
    props.setBooking(new Booking());
    props.setEditing(false);
    setErrorMessage('');
  }

  useEffect(() => {
    setPosition(props.booking.atcPosition);
    setStartDate(props.booking.startDate);
    setEndDate(props.booking.endDate);
    setUsesVoice(props.booking.voice);
    setTraining(props.booking.training ?? null);
  }, [props.booking]);

  useEffect(() => {
    if (props.booking.id) return; // Skip if booking already exists
    setStartDate(props.shownDate.date);
    setEndDate(props.shownDate.date);
  }, [props.booking.id, props.shownDate]);

  const submitData = async () => {
    if (
      position &&
      startDate &&
      endDate &&
      startTime &&
      endTime &&
      userPermissions
    ) {
      const temp_startDate = concatTime(startDate, startTime);
      let temp_endDate = concatTime(endDate, endTime);

      if (dayjs(temp_endDate).diff(temp_startDate) <= 0)
        temp_endDate = dayjs(temp_endDate).add(1, 'day').toDate();
      // e.g. booking from 22z-01z or 22z-22z -> next day
      else if (dayjs(temp_endDate).diff(temp_startDate, 'day') > 0)
        temp_endDate.setDate(temp_startDate.getDate()); // e.g. booking from 22z-23z -> same day except if local time overflows to next day

      const timeError = checkBookingTime(
        temp_startDate,
        temp_endDate,
        Object.keys(userPermissions),
      );
      try {
        await checkUserFras(
          props.userId,
          position,
          temp_startDate.toISOString(),
        );
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          const axiosError = err as AxiosError<ApiErrorResponse>;
          if (axiosError.response?.data?.message)
            return showError(
              'Position not match with your rating',
              axiosError.response?.data?.message,
            );
        }
        return showError(
          'Position not match with your rating',
          'An error occurred while checking the FRAs',
        );
      }

      if (timeError)
        return showError('Selected date/time incorrect', timeError);

      showLoading();

      try {
        if (props.editing)
          await editBooking(
            props.booking.id!,
            new Booking(
              position,
              temp_startDate,
              temp_endDate,
              usesVoice,
              training,
              props.booking.id,
            ).parse_to_dto(),
          );
        else
          await createBooking(
            new Booking(
              position,
              temp_startDate,
              temp_endDate,
              usesVoice,
              training,
            ).parse_to_dto(),
          );

        props.setShownDate(new ShownDate(startDate));
        void queryClient.invalidateQueries(['bookings', startDate]);

        showFinishedLoading('SUCCESS');

        if (training) showTraining(training);

        props.setOpened(false);
        resetData();
      } catch (e: unknown) {
        showFinishedLoading('ERROR');
        if (e instanceof Error) setErrorMessage(e.message);
      }
    } else {
      showError(
        'Required fields missing',
        'Please make sure to fill all fields',
      );
    }
  };

  return (
    <Drawer
      opened={props.opened}
      closeButtonLabel="Close"
      onClose={() => {
        props.setOpened(false),
          setTimeout(() => {
            props.setBooking(new Booking());
            resetData();
          }, 1000);
      }}
      title="Enter your Booking"
      padding="xl"
      size="xl"
      className={styles.drawer}
    >
      <>
        <Stack spacing="md">
          <NumberInput
            label="Your VID"
            icon={<IconIdBadge2 />}
            value={props.userId}
            hideControls
            disabled
            color="ivao-blue"
          />
          <Autocomplete
            label="Enter the position you want to control"
            icon={<IconMapPin />}
            placeholder="Position"
            onChange={(val) => {
              setPosition(val.toUpperCase());
            }}
            value={position}
            color="ivao-blue"
            data={autoCompleteData}
            transition="pop-top-left"
            transitionDuration={20}
            transitionTimingFunction="ease"
          />{' '}
          {/* Ternary operator in data to show entries as soon as user starts to write */}
          <DatePicker
            label="Enter the day on which you want to control"
            icon={<IconCalendar />}
            placeholder="Day"
            value={startDate}
            onChange={(d: Date) => {
              const temp: Date = startDate;
              temp.setDate(d.getDate());
              temp.setMonth(d.getMonth());
              temp.setFullYear(d.getFullYear());
              setStartDate(temp);
              setEndDate(temp);
            }}
            minDate={dayjs(new Date())
              .add(new Date().getTimezoneOffset())
              .toDate()}
            dayStyle={(_, mod) => {
              return mod.selected
                ? { backgroundColor: '#0D2C99' }
                : { backgroundColor: 'inherit' };
            }}
            clearable={false}
          />
          <TimeRangeInput
            label="Enter the time range you want to control"
            description="Remember to use zulu time"
            icon={<IconClock />}
            value={[startTime, endTime]}
            onChange={([t1, t2]) => {
              setStartTime(t1);
              setEndTime(t2);
            }}
            color="ivao-blue"
          />
          <Checkbox
            checked={usesVoice === false}
            onChange={() => setUsesVoice(!usesVoice)}
            label="No Voice"
            color="ivao-blue"
          />
          <Checkbox
            checked={training === BookingType.TRAINING}
            onChange={() =>
              training === BookingType.TRAINING
                ? setTraining(null)
                : setTraining(BookingType.TRAINING)
            }
            label="This is a training"
            color="ivao-blue"
          />
          <Checkbox
            checked={training === BookingType.EXAM}
            onChange={() =>
              training === BookingType.EXAM
                ? setTraining(null)
                : setTraining(BookingType.EXAM)
            }
            label="This is an exam"
            color="ivao-blue"
          />
          {props.editing ? (
            <Button
              variant="primary"
              color="default"
              onClick={() => void submitData()}
              style={{ borderRadius: '10px' }}
            >
              Edit the Booking
            </Button>
          ) : (
            <Button
              variant="primary"
              color="default"
              onClick={() => void submitData()}
              style={{ borderRadius: '10px' }}
            >
              Submit your Booking
            </Button>
          )}
          {errorMessage ? (
            <div className={styles.errorBox}>
              <p className={styles.errorText}>{errorMessage}</p>
            </div>
          ) : (
            ''
          )}
        </Stack>
      </>
    </Drawer>
  );
};

export default BookingDrawer;
