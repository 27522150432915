import { Button, Layout, Slider } from '@ivaoaero/design-system';

import { Flex } from '@mantine/core';
import { Link } from 'react-router-dom';

import { Footer } from '../components/Footer';
import Header from '../components/Header';
import TodayBookings from '../components/TodayBookings';

export const Home = () => {
  return (
    <Layout>
      <Header />
      <Slider>
        <Slider.Title>IVAO ATC Service Center</Slider.Title>
        <Flex gap={15}>
          <Link to="/schedule">
            <Button
              variant="primary"
              color="default"
              style={{
                borderRadius: '10px',
                backgroundColor: 'white',
                color: '#0D2C99',
              }}
            >
              Checkout the full ATC Schedule here!
            </Button>
          </Link>
          <Link to="/fras">
            <Button
              variant="primary"
              color="default"
              style={{
                borderRadius: '10px',
                backgroundColor: 'white',
                color: '#0D2C99',
              }}
            >
              Checkout the FRAs from anywhere!
            </Button>
          </Link>
        </Flex>
      </Slider>
      <TodayBookings />
      <Footer />
    </Layout>
  );
};
