import {
  ActionIcon,
  Card,
  Col,
  Grid,
  Popover,
  TextInput,
  useMantineColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch, IconUser } from '@tabler/icons';
import dayjs from 'dayjs';

import { useUser } from '../../../auth';
import { ShownDate } from '../../types/shownDate';
import ClockUTC from '../ClockUTC';
import { MenuButtons } from '../MenuButtons';

interface MenuProps {
  date: ShownDate;
  setDate(date: ShownDate): void;
  filter?: string;
  setFilter(filter: string): void;
}

const Menu = (props: MenuProps) => {
  const [opened, { close, open }] = useDisclosure(false);
  const { colorScheme } = useMantineColorScheme();
  const { user } = useUser();

  const handleMyBookings = () => {
    if (props.filter == user.id.toString()) {
      props.setFilter('');
    } else {
      props.setFilter(user.id.toString());
    }
  };

  return (
    <Grid columns={1} justify="center">
      <Col>
        <Card shadow="lg" p="lg" radius="md" withBorder>
          <p
            style={{
              textAlign: 'center',
              fontFamily: 'Nunito Sans',
              fontWeight: 800,
            }}
          >
            {dayjs(props.date.date).format('dddd')} -{' '}
            {dayjs(props.date.date).format('YYYY/MM/DD')} - <ClockUTC />
          </p>
        </Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: '10px',
          }}
        >
          {user.id != 0 ? (
            <>
              <p style={{ width: '28px' }}></p>
              <TextInput
                placeholder="Filter for VID or position"
                value={props.filter}
                onChange={(e) => props.setFilter(e.currentTarget.value)}
                icon={<IconSearch />}
                styles={{
                  root: {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 10px',
                  },
                  input: {
                    textAlign: 'center',
                    width: '50vw',
                    paddingRight: '36px',
                  },
                }}
              />
              <Popover
                width={200}
                position="bottom"
                withArrow
                shadow="md"
                opened={opened}
              >
                <Popover.Target>
                  <ActionIcon
                    onMouseEnter={open}
                    onMouseLeave={close}
                    size={36}
                    onClick={handleMyBookings}
                  >
                    <IconUser size={30} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
                  <p
                    style={{ color: colorScheme === 'dark' ? 'white' : 'dark' }}
                  >
                    Show my bookings only
                  </p>
                </Popover.Dropdown>
              </Popover>
            </>
          ) : (
            <TextInput
              placeholder="Filter for VID or position"
              value={props.filter}
              onChange={(e) => props.setFilter(e.currentTarget.value)}
              icon={<IconSearch />}
              styles={{
                root: {
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0 10px',
                },
                input: {
                  textAlign: 'center',
                  width: '50vw',
                  paddingRight: '36px',
                },
              }}
            />
          )}
        </div>
        <MenuButtons date={props.date} setDate={(d) => props.setDate(d)} />
      </Col>
    </Grid>
  );
};

export default Menu;
