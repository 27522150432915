import { Booking } from '@ivaoaero/atc';

import {
  Badge,
  Col,
  createStyles,
  Grid,
  Modal,
  Paper,
  Text,
  Title,
} from '@mantine/core';
import { IconCheck, IconMicrophone, IconX } from '@tabler/icons';
import moment from 'moment-timezone';

interface BookingModalProps {
  opened: boolean;
  close(): void;
  booking?: Booking;
}

const useStyles = createStyles((theme) => ({
  modalTitle: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
    }`,
  },
  listItem: {
    margin: `${theme.spacing.sm}px 0`,
  },
  badge: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing.xs,
  },
}));

const BookingModal = (props: BookingModalProps) => {
  const classes = useStyles();

  const modalTitle = `${props.booking?.user.firstName} ${props.booking?.user.lastName} (${props.booking?.user.id}) | Booking (${props.booking?.id})`;

  return (
    <Modal
      opened={props.opened}
      onClose={() => void props.close()}
      size="lg"
      overlayBlur={3}
      overlayOpacity={0.55}
      withCloseButton
    >
      <Paper className={classes.classes.modalTitle}>
        <Title order={2} align="center">
          {modalTitle}
        </Title>
      </Paper>
      <Grid>
        <Col xs={12} md={6} style={{ marginBottom: '16px' }}>
          <Text className={classes.classes.listItem}>
            <b>Position:</b> {props.booking?.atcPosition} (
            {props.booking?.atcPositionRef?.atcCallsign ?? 'N/A'})
          </Text>
          <Text className={classes.classes.listItem}>
            <b>Start:</b>{' '}
            {moment(props.booking?.startDate)
              .utc(true)
              .format('dddd, MMMM Do YYYY, HH:mm')
              .toString()}
          </Text>
          <Text className={classes.classes.listItem}>
            <b>End:</b>{' '}
            {moment(props.booking?.endDate)
              .utc(true)
              .format('dddd, MMMM Do YYYY, HH:mm')
              .toString()}
          </Text>
        </Col>
        <Col xs={12} md={6}>
          <Text className={classes.classes.listItem}>
            <b>Training/Exam:</b>{' '}
            <Badge
              h={26}
              className={classes.classes.badge}
              color={props.booking?.training ? 'teal' : 'red'}
            >
              {props.booking?.training ? <IconCheck /> : <IconX />}
            </Badge>
          </Text>
          <Text className={classes.classes.listItem}>
            <b>Voice:</b>{' '}
            <Badge
              h={26}
              className={classes.classes.badge}
              color={props.booking?.voice ? 'teal' : 'red'}
            >
              {props.booking?.voice ? <IconMicrophone /> : <IconX />}
            </Badge>
          </Text>
          <Text className={classes.classes.listItem}>
            <b>Booked At:</b>{' '}
            {moment(props.booking?.createdAt)
              .utc(false)
              .format('dddd, MMMM Do YYYY, HH:mm')
              .toString()}
          </Text>
        </Col>
      </Grid>
    </Modal>
  );
};

export default BookingModal;
