import dayjs from 'dayjs';

export class ShownDate {
  private _date!: Date;

  public get date(): Date {
    return this._date;
  }

  private set date(date: Date) {
    this._date = date;
  }

  constructor(
    date: Date = dayjs(new Date())
      .add(new Date().getTimezoneOffset(), 'minutes')
      .toDate(),
  ) {
    this.date = dayjs(date).hour(0).minute(0).second(0).toDate();
  }

  increment() {
    return dayjs(this.date).add(1, 'day').hour(0).minute(0).second(0).toDate();
  }

  reduce() {
    const new_date: Date = dayjs(this.date)
      .subtract(1, 'day')
      .hour(0)
      .minute(0)
      .second(0)
      .toDate();

    return dayjs(new_date).diff(
      dayjs(new Date()).hour(0).minute(0).second(0),
      'day',
    ) < -2
      ? this.date
      : new_date;
  }
}
