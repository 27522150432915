import { FC } from 'react';

import { WebStorageStateStore } from 'oidc-client-ts';
import {
  AuthProviderProps,
  AuthProvider as InternalAuthProvider,
} from 'react-oidc-context';

import {
  OPENID_AUTHORITY,
  OPENID_CLIENT_ID,
  OPENID_REDIRECT_URI,
} from '../configuration';

const oidcConfig: AuthProviderProps = {
  authority: OPENID_AUTHORITY,
  client_id: OPENID_CLIENT_ID,
  redirect_uri: OPENID_REDIRECT_URI,
  scope: 'profile configuration bookings:read bookings:write',
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  revokeTokensOnSignout: true,
};

interface Props {
  children: JSX.Element;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  return (
    <InternalAuthProvider {...oidcConfig}>{children}</InternalAuthProvider>
  );
};
