import { useEffect, useState } from 'react';

const formatNumber = (number: number): string => {
  return number.toString().padStart(2, '0');
};

const ClockUTC = () => {
  const [displayedDate, setDisplayedDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {formatNumber(displayedDate.getUTCHours())}:
      {formatNumber(displayedDate.getUTCMinutes())}:
      {formatNumber(displayedDate.getUTCSeconds())} UTC
    </>
  );
};

export default ClockUTC;
