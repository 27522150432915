export const RatingColorCode = [
  '#933a38', // AS1-3
  '#933a38',
  '#933a38',
  '#933a38',
  '#933a38',
  '#386d39', //ADC
  '#2e6c8a', //APC
  '#8d6c33', //ACC
  '#52585c', //SEC
  '#52585c', //SAI
  '#52585c', //CAI
];

export const RatingName = [
  'Invalid',
  'Invalid',
  'AS1',
  'AS2',
  'AS3',
  'ADC',
  'APC',
  'ACC',
  'SEC',
  'SAI',
  'CAI',
];
