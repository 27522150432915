import { useAuth as __useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
  const auth = __useAuth();
  const navigate = useNavigate();

  auth.startSilentRenew();

  if (auth.error) console.error(auth.error);

  const signIn = () => {
    let redirect = window.location.href.substring(
      window.location.origin.length,
    );

    if (redirect === '/logout' || redirect === 'logout') redirect = '/';

    sessionStorage.setItem('redirect', redirect);
    void auth.signinRedirect();
  };

  const hasRedirect = () => sessionStorage.getItem('redirect');

  const redirect = () => {
    const redirectUrl = sessionStorage.getItem('redirect');

    if (!redirectUrl) return;

    sessionStorage.removeItem('redirect');
    navigate(redirectUrl);
  };

  const signOut = async (ssoLogout: boolean = false) => {
    if (ssoLogout) await auth.signoutRedirect();
    else {
      await auth.revokeTokens(['access_token']);
      await auth.removeUser();
      return window.location.replace(
        auth.settings.post_logout_redirect_uri ?? 'https://atc.ivao.aero',
      );
    }
  };

  return { ...auth, signIn, hasRedirect, redirect, signOut };
};
