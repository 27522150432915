import { useQuery } from 'react-query';

import Http from '../../../utils/Http';
import { useAuth } from '../../auth/hooks/useAuth';

type UserSettings = {
  map_layer: string;
  name_public: boolean;
  dark_mode: boolean;
  tracks_interval: number;
  timeline: boolean;
  sso_logout: boolean;
};

const fetchUserSettings = () =>
  Http<UserSettings>({
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/users/me/settings/atc`,
    params: { default: true },
  });

export const useSettings = () => {
  const auth = useAuth();
  const query = useQuery(['user', 'settings'], fetchUserSettings, {
    enabled: auth.isAuthenticated,
    staleTime: Infinity,
  });

  return query;
};
