import { IFra } from '../modules/core/types/fras';

const getFRATimelinePeriod = (): { startDate: Date; endDate: Date } => {
  const startDate = new Date();
  const endDate = new Date();

  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(23, 59, 0, 0);

  if (startDate.getUTCDay() == 0) {
    // Today is Sunday
    startDate.setDate(startDate.getDate() - 6); // Start date is 6 days ago (Monday)
    endDate.setDate(endDate.getDate()); // End date is today
  } else {
    startDate.setDate(startDate.getDate() - (startDate.getUTCDay() - 1)); // Start date is Monday of this week
    endDate.setDate(endDate.getDate() + (7 - endDate.getUTCDay())); // End date is Sunday of this week
  }

  return { startDate, endDate };
};

type FRAChannel = { logo: string; uuid: string };

interface FRAEPG {
  id: string;
  channelUuid: string;
  description: string;
  title: string;
  since: Date;
  till: Date;
  image: string;
}
interface FRAChannelsAndEPG {
  channels: FRAChannel[];
  epg: FRAEPG[];
  startDate: Date;
  endDate: Date;
}

const isFraEndNextDay = (fra: IFra): boolean => fra.endTime <= fra.startTime;

export const getFRATimelineProps = (fras?: IFra[]): FRAChannelsAndEPG => {
  const { startDate, endDate } = getFRATimelinePeriod();
  const channels: FRAChannel[] = [];
  const epg: FRAEPG[] = [];

  fras?.forEach((fra) => {
    const channelString =
      fra.atcPosition?.composePosition ?? fra.subcenter?.composePosition;

    if (!channelString) {
      console.error('FRA without composePosition', fra);

      return;
    }

    if (!channels.find((channel) => channel.uuid == channelString)) {
      // If channel doesn't exist in channels array
      // Add channel to channels array
      channels.push({
        logo: channelString,
        uuid: channelString,
      });
    }

    const descriptionString =
      fra.atcPosition?.atcCallsign ?? fra.subcenter?.atcCallsign ?? '';

    [
      fra.dayMon,
      fra.dayTue,
      fra.dayWed,
      fra.dayThu,
      fra.dayFri,
      fra.daySat,
      fra.daySun,
    ].forEach((day, index) => {
      if (day) {
        // If FRA active on that day
        const since = new Date(startDate);
        const till = new Date(startDate);

        since.setUTCHours(
          parseInt(fra.startTime.split(':')[0]),
          parseInt(fra.startTime.split(':')[1]),
          0,
          0,
        );
        till.setUTCHours(
          parseInt(fra.endTime.split(':')[0]),
          parseInt(fra.endTime.split(':')[1]),
          0,
          0,
        );

        since.setUTCDate(startDate.getUTCDate() + index);
        till.setUTCMonth(since.getUTCMonth());
        till.setUTCDate(since.getUTCDate() + (isFraEndNextDay(fra) ? 1 : 0));

        // Add FRA to EPG
        epg.push({
          id: `${fra.id}-${index}`,
          channelUuid: channelString,
          description: fra as unknown as string, // Pass object for rendering purposes
          title: descriptionString,
          since: since,
          till: till,
          image: '',
        });
      }
    });
    if (fra.daySun) {
      const sundayEntry = epg[epg.length - 1];
      epg.push({
        ...sundayEntry,
        id: `${fra.id}-7`,
        since: new Date(sundayEntry.since.getTime() - 7 * 24 * 60 * 60 * 1000),
        till: new Date(sundayEntry.till.getTime() - 7 * 24 * 60 * 60 * 1000),
      });
    }
  });

  // // Sort Fras positions alphabetically
  channels.sort((a, b) => {
    if (a.uuid > b.uuid) {
      return 1;
    } else if (a.uuid < b.uuid) {
      return -1;
    } else {
      return 0;
    }
  });

  return { channels, epg, startDate, endDate };
};
