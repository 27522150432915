import { PropsWithChildren } from 'react';

import { Layout as GeneralLayout } from '@ivaoaero/design-system';

import styles from './index.module.scss';

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.background}>
      <GeneralLayout>{children}</GeneralLayout>
    </div>
  );
};

export default Layout;
