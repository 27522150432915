import { Pagination, Stack, Text, useMantineColorScheme } from '@mantine/core';

import styles from './index.module.scss';

interface PaginationProps {
  activePage: number;
  setActivePage(activePage: number): void;
  totalPages: number;
}

const FrasPagination = (props: PaginationProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <div className="mx-auto">
        <Pagination
          page={props.activePage}
          onChange={(p) => p && props.setActivePage(p)}
          total={props.totalPages}
        />
      </div>
      <Stack spacing="xs" className="mx-auto text-white" align="center">
        <Text
          className={`${
            colorScheme === 'dark' ? `text-white` : 'text-black'
          } text-center font-bold`}
        >
          Color codes:
        </Text>
        <p
          className={styles.legend}
          style={{
            backgroundColor: '#2EC662',
          }}
        >
          You have the necessary rating
        </p>
        <p
          className={styles.legend}
          style={{
            backgroundColor: '#E93434',
          }}
        >
          You don&apos;t have the necessary rating
        </p>
        <Text
          className={`${
            colorScheme === 'dark' ? `text-white` : 'text-black'
          } text-center italic`}
        >
          Keep in mind you need to be allowed to control in the selected
          division
        </Text>
      </Stack>
    </>
  );
};

export default FrasPagination;
