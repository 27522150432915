import { Button, Navbar } from '@ivaoaero/design-system';

import {
  Group,
  Switch,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons';

import { useAuth, useUser } from '../../../auth';
import { useUpdateSettings } from '../../../auth/hooks/useUpdateSettings';
import { showSuccess } from '../Notifications';
import styles from './index.module.scss';

const FrasHeader = () => {
  const auth = useAuth();
  const { user } = useUser();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const updateSettings = useUpdateSettings();

  return (
    <>
      <header className={styles.header}>
        <Navbar>
          <Group className={styles.button}>
            {auth.isAuthenticated ? (
              <Navbar.Dropdown>
                <Group position="apart">
                  <Navbar.Dropdown.Trigger>{`${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}</Navbar.Dropdown.Trigger>
                  <Navbar.Dropdown.Items>
                    <Navbar.Link
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      DARKMODE:
                      <Switch
                        size="sm"
                        checked={colorScheme === 'dark'}
                        offLabel={
                          <IconSun
                            size={16}
                            stroke={2.5}
                            color={theme.colors.dark[4]}
                          />
                        }
                        onLabel={
                          <IconMoonStars
                            size={16}
                            stroke={2.5}
                            color={theme.colors.yellow[4]}
                          />
                        }
                        onClick={() => {
                          toggleColorScheme();
                          updateSettings.mutate({
                            key: 'dark_mode',
                            value: !(colorScheme === 'dark'),
                          });
                          showSuccess(
                            'Color theme changed',
                            colorScheme === 'dark'
                              ? 'Thanks for changing the lightbulb!'
                              : 'Bravo six, going dark!',
                          );
                        }}
                        color={'ivao.4'} //ivao-color
                        styles={{ root: { width: '50px' } }}
                      />
                    </Navbar.Link>
                    <Navbar.Link href="/logout">LOGOUT</Navbar.Link>
                  </Navbar.Dropdown.Items>
                </Group>
              </Navbar.Dropdown>
            ) : (
              <Button
                variant="primary"
                color="default"
                onClick={() => auth.signIn()}
                style={{ borderRadius: '10px' }}
              >
                LOGIN
              </Button>
            )}
          </Group>
        </Navbar>
      </header>
    </>
  );
};

export default FrasHeader;
