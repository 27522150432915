import { useState } from 'react';

import { Button, PulseLoader } from '@ivaoaero/design-system';

import { Alert, Title, useMantineTheme } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';

import useGetAllBookings from '../../../../utils/hooks/useGetAllBookings';
import { useAuth } from '../../../auth';
import { ShownDate } from '../../types/shownDate';
import { Centered } from '../Centered';
import { Day } from '../Day';

const ReadOnlyTodayBookings = () => {
  const [shownDate] = useState<ShownDate>(new ShownDate());
  const allBookings = useGetAllBookings(shownDate.date);

  return allBookings.data && !allBookings.isLoading ? (
    <Day
      bookings={allBookings.data}
      date={shownDate}
      edit={() => {}}
      filter=""
      readOnly={true}
    />
  ) : (
    <Centered>
      <PulseLoader />
    </Centered>
  );
};

const TodayBookings = () => {
  const { colorScheme } = useMantineTheme();
  const auth = useAuth();
  return (
    <div className="container mx-auto">
      <Title
        color={colorScheme === 'dark' ? 'white' : 'black'}
        align="center"
        className="my-5"
      >
        Today&apos;s Bookings
      </Title>
      {auth.isAuthenticated ? (
        <ReadOnlyTodayBookings />
      ) : (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="You are not logged in"
        >
          <Button onClick={() => auth.signIn()} color="info">
            Log in to see today&apos;s bookings
          </Button>
        </Alert>
      )}
    </div>
  );
};

export default TodayBookings;
