import { Footer as FooterComponent } from '@ivaoaero/design-system';

import styles from './index.module.scss';

export const Footer = () => {
  return (
    <>
      <div className={styles.footer}>
        <FooterComponent />
      </div>
    </>
  );
};
