import { useMutation, useQueryClient } from 'react-query';

import Http from '../../../utils/Http';
import { useSettings } from './useSettings';

const updateSettingAction = async ({
  key,
  value,
  type = 'default',
}: {
  key: string;
  value: string | number | boolean;
  type?: string;
}) => {
  return Http({
    url: `${
      import.meta.env.DEV ? 'https://api.stage.ivao.aero' : ''
    }/v2/users/me/settings/${type}`,
    method: 'PUT',
    data: { key, value },
  });
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  const settings = useSettings();

  const updateSettings = useMutation(updateSettingAction, {
    onMutate: (data) => {
      queryClient.setQueryData(['user', 'settings'], {
        ...settings.data,
        [data.key]: data.value,
      });
    },
    onError: () => queryClient.invalidateQueries(['user', 'settings']),
  });

  return updateSettings;
};
