import {
  Flex,
  Select,
  Text,
  TextInput,
  useMantineColorScheme,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons';

import useGetAllDivisions from '../../../../utils/hooks/useGetAllDivisions';
import { ShownDate } from '../../types/shownDate';

interface MenuProps {
  date: ShownDate;
  setDate(date: ShownDate): void;
  filter?: string;
  setFilter(filter: string): void;
  perPage: number;
  setPerPage(perPage: number): void;
  divisionFilter?: string;
  setDivisionFilter(division: string): void;
}

const FrasMenu = (props: MenuProps) => {
  const { colorScheme } = useMantineColorScheme();
  const { data: allDivisions } = useGetAllDivisions();

  return (
    <Flex justify="space-between" align="stretch" gap={15} wrap="wrap">
      <TextInput
        placeholder="Search a position"
        defaultValue={props.filter}
        onChange={(e) => props.setFilter(e.currentTarget.value)}
        icon={<IconSearch />}
        styles={{
          root: { flex: 1 },
          input: { textAlign: 'center', width: '100%', paddingRight: '36px' },
        }}
        miw={300}
      />
      <Flex gap={5} align="baseline" miw={300}>
        <Text color={colorScheme == 'dark' ? 'white' : 'dark'}>Division: </Text>
        <Select
          placeholder="Choose a division"
          data={
            allDivisions?.map(({ id }) => id) ?? [props.divisionFilter ?? '']
          }
          defaultValue={props.divisionFilter}
          value={props.divisionFilter}
          onChange={(d) => d && props.setDivisionFilter(d)}
          nothingFound="Nothing found..."
          searchable
          clearable
        />
      </Flex>
      <Flex gap={5} align="baseline" miw={300}>
        <Text color={colorScheme == 'dark' ? 'white' : 'dark'}>Per Page: </Text>
        <Select
          placeholder="Per Page"
          defaultValue="30"
          value={props.perPage.toString()}
          onChange={(p) => p && props.setPerPage(parseInt(p))}
          data={[
            { value: '15', label: '15' },
            { value: '30', label: '30' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
          ]}
        />
      </Flex>
    </Flex>
  );
};

export default FrasMenu;
