import { Booking } from '@ivaoaero/atc';

import { useQuery } from 'react-query';

import { BookingError, getAllBookings } from '../ApiRequest';

const useGetAllBookings = (date: Date) => {
  return useQuery<Booking[], BookingError>(
    ['bookings', date],
    () => getAllBookings(date),
    {
      staleTime: 5 * 3600 * 1000,
      useErrorBoundary: true,
      retry: (failureCount) => {
        return failureCount <= 5;
      },
    },
  );
};

export default useGetAllBookings;
