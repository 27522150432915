import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { User } from 'oidc-client-ts';

import {
  OPENID_AUTHORITY,
  OPENID_CLIENT_ID,
} from '../modules/auth/configuration';

const Http = Axios.create({
  baseURL: import.meta.env.VITE_IVAO_API_BASE_URL as string,
});

Http.interceptors.response.use(
  (response: AxiosResponse) => response.data as unknown,
  (error) => {
    if (
      error instanceof AxiosError &&
      error.response &&
      error.response.status === 401
    )
      window.location.reload();

    return Promise.reject(error);
  },
);

Http.interceptors.request.use((request) => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${OPENID_AUTHORITY}:${OPENID_CLIENT_ID}`,
  );

  if (!oidcStorage) return request;

  const user = User.fromStorageString(oidcStorage);

  if (!user || !user.access_token) return request;

  request.headers = request.headers ?? {};
  request.headers['Authorization'] = `Bearer ${user.access_token}`;

  if (import.meta.env.DEV) {
    request.headers['x-authenticated-agent-type'] = 'USER';
    request.headers['x-authenticated-agent-id'] = import.meta.env
      .VITE_DEV_USER_VID as string;
    request.headers['x-authenticated-agent-scope'] =
      'bookings:read bookings:write profile configuration';
    request.headers['x-authenticated-agent-permission'] =
      'ATC_SCHEDULING_STAFF';
  }

  return request;
});

type HttpInterface = <T>(config: AxiosRequestConfig) => Promise<T>;

export default Http as HttpInterface;
