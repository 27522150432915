import { HoverCard, Stack, Text, useMantineColorScheme } from '@mantine/core';
import {
  Channel,
  ChannelBox,
  Epg,
  Layout,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramItem,
  ProgramStack,
  ProgramTitle,
  TimelineBox,
  TimelineDivider,
  TimelineDividers,
  TimelineTime,
  TimelineWrapper,
  useEpg,
  useProgram,
  useTimeline,
} from 'planby';

import { getFRATimelineProps } from '../../../../utils/FRAUtils';
import { RatingName } from '../../../../utils/Ratings';
import { useUser } from '../../../auth';
import { useTimelineTheme } from '../../types/colorScheme';
import { IFra } from '../../types/fras';
import { ShownDate } from '../../types/shownDate';
import customStyles from './index.module.scss';

interface CustomProgramItemProps {
  programItem: ProgramItem;
  fras: IFra;
}

const CustomProgramItem = (props: CustomProgramItemProps) => {
  const { isRTL, isLive, styles } = useProgram(props.programItem);
  const { user } = useUser();
  const { colorScheme } = useMantineColorScheme();

  return (
    <HoverCard
      width={200}
      position="bottom"
      openDelay={100}
      closeDelay={100}
      withArrow
      shadow="md"
    >
      <HoverCard.Target>
        <ProgramBox width={styles.width} style={styles.position}>
          <ProgramContent
            width={styles.width}
            isLive={isLive}
            style={{
              padding: '0px',
              height: '100%',
            }}
          >
            <ProgramFlex>
              <ProgramStack isRTL={isRTL} style={{ width: '100%' }}>
                <ProgramTitle
                  style={{
                    backgroundColor:
                      user.rating.atcRating &&
                      (props.fras.minAtc ?? 0) <= user.rating.atcRating.id
                        ? '#2EC662'
                        : '#E93434',
                    paddingTop: '5px',
                    height: '100%',
                  }}
                >
                  <span className={customStyles.programText}>
                    <p className="text-white">
                      {RatingName[props.fras.minAtc ?? 0]}
                    </p>
                  </span>
                </ProgramTitle>
              </ProgramStack>
            </ProgramFlex>
          </ProgramContent>
        </ProgramBox>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm" color={colorScheme === 'dark' ? 'white' : 'dark'}>
          <b>
            {props.fras.atcPosition?.composePosition ??
              props.fras.subcenter?.composePosition}
          </b>{' '}
          -{' '}
          <span>
            {props.fras.atcPosition?.atcCallsign ??
              props.fras.subcenter?.atcCallsign}
          </span>
        </Text>
        <Text size="sm" color={colorScheme === 'dark' ? 'white' : 'dark'}>
          Required <b>{RatingName[props.fras.minAtc ?? 0]}</b> rating
        </Text>
        <Text size="sm" color={colorScheme === 'dark' ? 'white' : 'dark'}>
          From <b>{props.fras.startTime.substring(0, 5)} UTC</b> to{' '}
          <b>{props.fras.endTime.substring(0, 5)} UTC</b>
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

interface ChannelProps {
  channel: Channel;
}

const ChannelItem = ({ channel }: ChannelProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <ChannelBox {...channel.position}>
      <p
        style={{
          color: colorScheme === 'dark' ? '#fff' : '#000',
          textAlign: 'center',
          fontFamily: 'Nunito Sans',
          fontWeight: '600',
          fontSize: '14px',
        }}
      >
        {channel.logo}
      </p>
    </ChannelBox>
  );
};

const dayOfTheWeekName = (day: number) => {
  return [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ][day];
};

interface TimelineProps {
  isBaseTimeFormat: boolean;
  isSidebar: boolean;
  dayWidth: number;
  hourWidth: number;
  numberOfHoursInDay: number;
  offsetStartHoursRange: number;
  sidebarWidth: number;
}

export function Timeline({
  isBaseTimeFormat,
  isSidebar,
  hourWidth,
  numberOfHoursInDay,
  sidebarWidth,
}: TimelineProps) {
  const { time, dividers } = useTimeline(numberOfHoursInDay, isBaseTimeFormat);
  const timelineTheme = useTimelineTheme();

  const renderTime = (index: number, dayOfTheWeek: number) => (
    <TimelineBox key={index} width={hourWidth}>
      <TimelineTime
        style={{
          textAlign: 'center',
          width: '100%',
          border: `1px solid ${timelineTheme.timeline.divider.bg}`,
          borderWidth: '0 1px 0 1px',
        }}
      >
        {dayOfTheWeekName(dayOfTheWeek)}
      </TimelineTime>
      <TimelineDividers>{renderDividers()}</TimelineDividers>
    </TimelineBox>
  );

  const renderDividers = () =>
    dividers.map((_: unknown, index: number) => (
      <TimelineDivider key={index} width={24} />
    ));

  return (
    <div
      className="flex"
      style={{
        marginLeft: `${sidebarWidth}px`,
      }}
    >
      {Array.from({ length: 7 }).map((_, dayIndex) => (
        <TimelineWrapper
          dayWidth={hourWidth}
          sidebarWidth={sidebarWidth}
          isSidebar={isSidebar}
          key={dayIndex}
        >
          {time.map((_: unknown, timeIndex: number) =>
            renderTime(timeIndex * (24 / time.length), dayIndex),
          )}
        </TimelineWrapper>
      ))}
    </div>
  );
}

interface FrasTimelineProps {
  fras?: IFra[];
  date: ShownDate;
  userId: number;
}

export const FrasTimeline = (props: FrasTimelineProps) => {
  const { channels, epg, startDate, endDate } = getFRATimelineProps(props.fras);
  const { getEpgProps, getLayoutProps } = useEpg({
    epg,
    channels,
    startDate,
    endDate,
    dayWidth: 1750,
    isLine: false, // Currently it is displayed in local time, so disabling it until a fix is found https://github.com/karolkozer/planby/issues/19
    isTimeline: true,
    itemHeight: 40,
    sidebarWidth: 120,
    theme: useTimelineTheme(),
  });

  return (
    <Stack className="max-w-[95vw]" spacing="md">
      <Epg {...getEpgProps()}>
        <Layout
          {...getLayoutProps()}
          renderTimeline={(props) => (
            <Timeline
              {...props}
              dayWidth={1500}
              hourWidth={240}
              numberOfHoursInDay={1}
            />
          )}
          renderChannel={({ channel }) => (
            <ChannelItem key={channel.uuid} channel={channel} />
          )}
          renderProgram={(programItem) => {
            return (
              <CustomProgramItem
                key={programItem.program.data.id}
                programItem={programItem}
                // Passing the FRA object as `description`
                fras={programItem.program.data.description as unknown as IFra}
              />
            );
          }}
        />
      </Epg>
    </Stack>
  );
};
