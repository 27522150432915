export const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        margin: '0px',
        backgroundColor: '#0D2C99',
        padding: '20px',
        color: 'white',
      }}
    >
      <div>
        <p
          style={{
            fontSize: '150px',
            fontWeight: 'bolder',
            textAlign: 'center',
          }}
        >
          404
        </p>
        <br />
        <p
          style={{
            fontSize: '80px',
            fontWeight: 'bolder',
            textAlign: 'center',
          }}
        >
          Seems like you&apos;re flying blind!
        </p>
        <br />
        <p style={{ fontSize: '30px', textAlign: 'center' }}>
          You can return to the home base{' '}
          <a
            href="/"
            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
          >
            here
          </a>
          .<br />
          Consider squawking 7600 and checking your systems once you&apos;re
          back!
        </p>
      </div>
    </div>
  );
};
