import { showNotification } from '@mantine/notifications';
import { useQuery } from 'react-query';

import { IFras } from '../../modules/core/types/fras';
import { getAllFras } from '../ApiRequest';

const useGetAllFras = (
  division: string | undefined,
  page: number,
  perPage: number,
  isActive: boolean,
  expand: boolean,
  startsWith?: string,
) => {
  return useQuery<IFras>(
    ['fras', division, page, perPage, isActive, expand, startsWith],
    () => getAllFras(division, page, perPage, isActive, expand, startsWith),
    {
      staleTime: 5 * 3600 * 1000,
      retry: (failureCount) => {
        return failureCount <= 5;
      },
      onError: () => {
        showNotification({
          title: 'Error!',
          message: 'I could not load fras data.',
          color: 'red',
          styles: { title: { fontWeight: 'bold' } },
        });
      },
    },
  );
};

export default useGetAllFras;
