import { useCallback, useEffect } from 'react';

import { showError } from '../../core/components/Notifications';
import { useSettings } from './useSettings';

export const useTimeline = () => {
  const settings = useSettings();

  const isUsingTimeline = useCallback(() => {
    if (settings.status !== 'success' && !settings.isLoading) {
      document.body.setAttribute('display', 'timeline');

      return showError(
        'Error loading your appearance preferences',
        'Will display timeline',
      );
    }

    return settings.data?.timeline;
  }, [settings]);

  useEffect(() => {
    if (isUsingTimeline()) {
      document.body.setAttribute('display', 'timeline');
    } else {
      document.body.setAttribute('display', 'cards');
    }
  }, [isUsingTimeline]);

  return isUsingTimeline();
};
