import { Button, Indicator, Popover, Switch } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import {
  IconArrowBigLeft,
  IconArrowBigRight,
  IconCalendar,
  IconLayoutList,
  IconTimeline,
} from '@tabler/icons';
import dayjs from 'dayjs';

import { useAuth } from '../../../auth';
import { useTimeline } from '../../../auth/hooks/useCheckTimeline';
import { useUpdateSettings } from '../../../auth/hooks/useUpdateSettings';
import { ShownDate } from '../../types/shownDate';
import { showSuccess } from '../Notifications';
import styles from './index.module.scss';

interface MenuButtonsProps {
  date: ShownDate;
  setDate(date: ShownDate): void;
}

export const MenuButtons = (props: MenuButtonsProps) => {
  const timeline = useTimeline();
  const auth = useAuth();
  const updateSettings = useUpdateSettings();

  if (auth.isAuthenticated) {
    return (
      <div className={styles.main}>
        <div className={styles.placeholder} />
        <div className={styles.buttons}>
          <Button
            variant="subtle"
            className={styles.button}
            onClick={() => props.setDate(new ShownDate(props.date.reduce()))}
          >
            <IconArrowBigLeft size={28} color={'white'} />
          </Button>
          <Popover width={300} position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Button variant="subtle" className={styles.button}>
                <IconCalendar size={28} color={'white'} />
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Calendar
                value={props.date.date}
                onChange={(i) => (i ? props.setDate(new ShownDate(i)) : {})}
                minDate={dayjs(new Date()).subtract(2, 'days').toDate()}
                dayStyle={(_, mod) => {
                  return mod.selected
                    ? { backgroundColor: '#3c55ac' }
                    : { backgroundColor: 'inherit' };
                }}
                renderDay={(date) => {
                  const isCurrentDay =
                    dayjs(date).format('YYYY-MM-DD') ===
                    dayjs(new Date())
                      .add(new Date().getTimezoneOffset())
                      .format('YYYY-MM-DD');
                  const day = date.getDate();
                  return (
                    <Indicator
                      size={6}
                      color="green"
                      offset={8}
                      disabled={!isCurrentDay}
                    >
                      <div>{day}</div>
                    </Indicator>
                  );
                }}
              />
            </Popover.Dropdown>
          </Popover>
          <Button
            variant="subtle"
            className={styles.button}
            onClick={() => props.setDate(new ShownDate(props.date.increment()))}
          >
            <IconArrowBigRight size={28} color={'white'} />
          </Button>
        </div>
        <Switch
          size="md"
          checked={timeline ?? false}
          onLabel={<IconTimeline size={16} stroke={2.5} />}
          offLabel={<IconLayoutList size={16} stroke={2.5} />}
          onClick={() => {
            document.body.setAttribute(
              'display',
              !timeline ? 'timeline' : 'cards',
            );
            updateSettings.mutate({
              key: 'timeline',
              value: !timeline,
              type: 'atc',
            });
            showSuccess(
              'Appearance changed',
              timeline ? 'Switching to cards' : 'Switching to timeline',
            );
          }}
          color={'ivao.4'} //ivao-color
          className={styles.switch}
        />
      </div>
    );
  } else {
    return (
      <div>
        <div className={styles.buttons}>
          <Button
            variant="subtle"
            className={styles.button}
            onClick={() => props.setDate(new ShownDate(props.date.reduce()))}
          >
            <IconArrowBigLeft size={28} color={'white'} />
          </Button>
          <Popover width={300} position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Button variant="subtle" className={styles.button}>
                <IconCalendar size={28} color={'white'} />
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Calendar
                value={props.date.date}
                onChange={(i) => (i ? props.setDate(new ShownDate(i)) : {})}
                minDate={new Date()}
                dayStyle={(_, mod) => {
                  return mod.selected
                    ? { backgroundColor: '#3c55ac' }
                    : { backgroundColor: 'inherit' };
                }}
                renderDay={(date) => {
                  const isCurrentDay =
                    dayjs(date).format('YYYY-MM-DD') ===
                    dayjs(new Date())
                      .add(new Date().getTimezoneOffset())
                      .format('YYYY-MM-DD');
                  const day = date.getDate();
                  return (
                    <Indicator
                      size={6}
                      color="green"
                      offset={8}
                      disabled={!isCurrentDay}
                    >
                      <div>{day}</div>
                    </Indicator>
                  );
                }}
              />
            </Popover.Dropdown>
          </Popover>
          <Button
            variant="subtle"
            className={styles.button}
            onClick={() => props.setDate(new ShownDate(props.date.increment()))}
          >
            <IconArrowBigRight size={28} color={'white'} />
          </Button>
        </div>
      </div>
    );
  }
};
