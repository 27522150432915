import { useState } from 'react';

import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core';

import './App.css';

import { PulseLoader } from '@ivaoaero/design-system';

import { useAuth, useDarkMode } from './modules/auth';
import { CoreRoutes } from './modules/core';
import { Centered } from './modules/core/components/Centered';

function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  const auth = useAuth();

  useDarkMode({ toggleColorScheme });

  if (auth.isLoading) {
    return (
      <Centered>
        <PulseLoader />
      </Centered>
    );
  }

  if (auth.hasRedirect()) {
    auth.redirect();
    return (
      <Centered>
        <PulseLoader />
      </Centered>
    );
  }

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          colorScheme,
          colors: {
            ivao: [
              '#3C55AC',
              '#3C55AC',
              '#3C55AC',
              '#3C55AC',
              '#3C55AC',
              '#0D2C99',
              '#0D2C99',
              '#0D2C99',
              '#0D2C99',
              '#0D2C99',
            ],
          },
        }}
      >
        <CoreRoutes />
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
